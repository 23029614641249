module.exports.commonSentryConfigurations = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_ENV,
  tracesSampleRate: 1,
  debug: false,
};

module.exports.clientSentryConfigurations = {
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
};

module.exports.sentryPrimaryNextConfigurations = {
  silent: true,
  org: process.env.SENTRY_ORG,
  project: process.env.SENTRY_PROJECT,
  authToken: process.env.SENTRY_AUTH_TOKEN,
};

module.exports.sentrySecondaryNextConfigurations = {
  widenClientFileUpload: true,
  transpileClientSDK: true,
  tunnelRoute: '/monitoring',
  hideSourceMaps: true,
  disableLogger: true,
};
